<template>
  <div class="create-meeting fs24 fc666">
    <!-- <icy-header class="meeting-box" title="发起会议"></icy-header> -->

    <app-title title="基本信息"></app-title>
    <!-- 所属领域 -->
    <div class="cell-box ">
      <div class="cell-title">所属领域</div>
      <div
        @click="showFieldHandle"
        class="inner fs28 dfr cross-center main-between"
      >
        <div v-if="!fieldValue" class="placeholder  ">
          请选择领域
        </div>
        <div v-else class="box fc333">{{ fieldValue }}</div>
        <van-icon name="arrow" color="#ccc" />
      </div>
    </div>
    <van-popup v-model="fieldPopupShow" position="bottom" style="height:3.9rem">
      <van-picker
        show-toolbar
        title=""
        :columns="fieldList"
        :default-index="fieldDefaultIndex"
        :visible-item-count="3"
        @confirm="fieldConfirm"
        @cancel="fieldPopupShow = false"
      >
        <icy-button
          width="1.2rem"
          height=".6rem"
          color="#fff"
          fontSize=".24rem"
          slot="confirm"
          background="#000"
          >确定</icy-button
        >
        <van-icon slot="cancel" class="fc000 fs32" name="arrow-down" />
      </van-picker>
    </van-popup>

    <!-- 会议日期 -->
    <div class="cell-box ">
      <div class="cell-title">会议日期</div>
      <div
        @click="showDateHandle"
        class="inner fs28 dfr cross-center main-between"
      >
        <div v-if="!dateValue" class="placeholder  ">
          请选择会议召开的日期
        </div>
        <div v-else class="box fc333">{{ dateValue }}</div>
        <van-icon name="arrow" color="#ccc" />
      </div>
    </div>
    <van-popup v-model="datePopupShow" position="bottom" style="height:3.9rem">
      <van-datetime-picker
        v-model="beDate"
        type="date"
        :visible-item-count="3"
        title=""
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="dateConfirm"
        @change="dateChange"
        :formatter="formatter"
        @cancel="datePopupShow = false"
      >
        <icy-button
          width="1.2rem"
          height=".6rem"
          color="#fff"
          fontSize=".24rem"
          background="#000"
          slot="confirm"
          >确定</icy-button
        >
        <van-icon slot="cancel" class="fc000 fs32" name="arrow-down" />
      </van-datetime-picker>
    </van-popup>

    <!-- 会议时间 -->
    <div class="cell-box ">
      <div class="cell-title">会议时间</div>
      <div
        @click="showTimeHandle"
        class="inner fs28 dfr cross-center main-between"
      >
        <div v-if="!timeValue" class="placeholder  ">
          请选择会议召开的时间
        </div>
        <div v-else class="box fc333">
          <!-- "09:00-12:00", "14:00-17:00", "19:00-21:00" -->
          <div class="dfr cross-center" v-if="timeValue == '09:00-12:00'">
            上午<span class="transform-y">(</span>
            <span class="transform-time">{{ timeValue }}</span>
            <span class="transform-y">)</span>
          </div>
          <div class="dfr cross-center" v-else-if="timeValue == '14:00-17:00'">
            下午<span class="transform-y">(</span>
            <span class="transform-time">{{ timeValue }}</span>
            <span class="transform-y">)</span>
          </div>
          <div class="dfr cross-center" v-else-if="timeValue == '19:00-21:00'">
            晚上<span class="transform-y">(</span>
            <span class="transform-time">{{ timeValue }}</span>
            <span class="transform-y">)</span>
          </div>
        </div>
        <van-icon name="arrow" color="#ccc" />
      </div>
    </div>
    <van-popup v-model="timePopupShow" position="bottom" style="height:3.9rem">
      <van-picker
        show-toolbar
        title=""
        :columns="timeList"
        :default-index="currentTimeIndex"
        :visible-item-count="3"
        @change="timeChange"
        @confirm="timeConfirm"
        @cancel="timePopupShow = false"
      >
        <icy-button
          width="1.2rem"
          height=".6rem"
          color="#fff"
          fontSize=".24rem"
          background="#000"
          slot="confirm"
          >确定</icy-button
        >
        <van-icon slot="cancel" class="fc000 fs32" name="arrow-down" />
      </van-picker>
    </van-popup>

    <!-- 选择会议的案主  只有替案主发会议才有的选项-->
    <div v-if="isReplace" class="cell-box">
      <div class="cell-title">会议的案主</div>
      <div @click="goSidong" class="inner fs28 dfr cross-center main-between">
        <div v-if="!sidongInfo || !sidongInfo.id" class="placeholder  ">
          请选择本次会议的案主
        </div>
        <div v-else class="box w100 fc333 dfr cross-center ">
          <div class="moderator-avatar-box dfr cross-center">
            <div class="avatar">
              <img
                :src="sidongInfo.avatar"
                class="w100 h100 object-cover"
                alt=""
              />
            </div>
            <div class="name fs28 fc333">{{ sidongInfo.name }}</div>
          </div>
          <!-- 公司 -->
          <div class=" ml16 fc999 fs24">
            {{ sidongInfo.company }}
          </div>
        </div>
        <van-icon name="arrow" color="#ccc" />
      </div>
    </div>

    <!-- 选择主持人 -->
    <div class="cell-box">
      <div class="cell-title">主持人</div>
      <div
        v-if="!isReplace"
        @click="goModerator"
        class="inner fs28 dfr cross-center main-between"
      >
        <div v-if="!moderatorInfo.id" class="placeholder  ">
          请选择主持人
        </div>
        <div v-else class="box w100 fc333 dfr cross-center">
          <div class="moderator-avatar-box dfr cross-center">
            <div class="avatar">
              <img
                :src="moderatorInfo.avatar"
                class="w100 h100 object-cover"
                alt=""
              />
            </div>
            <div class="name fs28 fc333">{{ moderatorInfo.name }}</div>
          </div>
          <div class="moderator-box ml50">
            <name-plate :nameplate="moderatorInfo.level_name"></name-plate>
          </div>
        </div>
        <van-icon name="arrow" color="#ccc" />
      </div>
      <!-- 代案主发起会议 主持人默认是自己 不能选择其他主持人 -->
      <div v-else class="inner fs28 dfr cross-center main-between">
        <!-- isHaveHostTime 0未开启会议时间  1开启了会议时间 -->
        <div v-if="isHaveHostTime">
          <!-- 1:当前日期时间段不能发起会议 0:能 -->
          <div v-if="isHostMeeting == 1" class="box w100 fc333">
            您的此日期时间段已有会议，请选择其他日期时间段！
          </div>
          <div
            class="box w100 fc333 dfr cross-center"
            v-else-if="dateValue && timeValue"
          >
            <div class="moderator-avatar-box dfr cross-center">
              <div class="avatar">
                <img
                  :src="moderatorInfo.avatar"
                  class="w100 h100 object-cover"
                  alt=""
                />
              </div>
              <div class="name fs28 fc333">{{ moderatorInfo.name }}</div>
            </div>
            <div class="moderator-box ml50">
              <name-plate :nameplate="moderatorInfo.level_name"></name-plate>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="dateValue && timeValue">
            您没有在此日期时间段设置会议日程，请前往【我的】页面设置我的可用日程档！
          </div>
          <div v-else>
            <div class="box w100 fc333 dfr cross-center">
              <div class="moderator-avatar-box dfr cross-center">
                <div class="avatar">
                  <img
                    :src="moderatorInfo.avatar"
                    class="w100 h100 object-cover"
                    alt=""
                  />
                </div>
                <div class="name fs28 fc333">{{ moderatorInfo.name }}</div>
              </div>
              <div class="moderator-box ml50">
                <name-plate :nameplate="moderatorInfo.level_name"></name-plate>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 参会私董人数 -->
    <div class="cell-box ">
      <div class="cell-title">参会私董人数</div>
      <div class="inner fs28 dfr cross-center main-between">
        <van-field
          clearable
          min="7"
          max="15"
          v-model="joinNum"
          @blur="joinBlur"
          type="number"
          placeholder="请输入参会私董人数(7-15)"
        />
      </div>
    </div>

    <!-- 会议召开形式 -->
    <div class="cell-box">
      <div class="cell-title">会议召开形式</div>
      <div
        @click="showMeetingMethodsHandle"
        class="inner fs28 dfr cross-center main-between"
      >
        <div v-if="!meetingMethods" class="placeholder  ">
          请选择会议召开形式
        </div>
        <div v-else class="box fc333">{{ meetingMethods }}</div>
        <van-icon name="arrow" color="#ccc" />
      </div>
    </div>
    <van-action-sheet
      v-model="meetingMethodsPopupShow"
      :actions="meetingMethodsList"
      cancel-text="取消"
      close-on-click-action
      @cancel="meetingMethodsCancel"
      @select="meetingMethodsSelect"
    />

    <div class="meeting-methods-wrap">
      <!-- //  1线下 2线上 -->
      <!-- 线上会议 -->
      <div v-if="meetingMethodsType == 2" class="meeting-methods-online dfr">
        <div class="online-left"></div>
        <div class="online-right flex1">
          <div class="online-title fs24 fc666">{{ onlineName }}</div>
          <div class="online-input-box mt20 fs28 fc333">
            <van-field
              clearable
              v-model="onlineRoom"
              @blur="onlineRoomBlur"
              :placeholder="`请填写${onlineName}`"
            />
          </div>
        </div>
      </div>
      <!-- 线下会议 -->
      <div v-if="meetingMethodsType == 1" class="meeting-methods-offline dfr">
        <div class="offline-left dfc">
          <div class="dot-top dot"></div>
          <div class="line"></div>
          <div class="dot-bottom dot"></div>
        </div>
        <div class="offline-right flex1">
          <!-- 会议场所 -->
          <div class="offline-place">
            <div class="offline-title fs24 fc666">
              会议场所
            </div>
            <div
              @click="showOfflineTypeHandle"
              class="mt20 fs28 fc333 dfr cross-center main-between"
            >
              <div v-if="!offlinePlaceTypeName" class="placeholder  fcccc ">
                请选择会议场所
              </div>
              <div v-else class="box fc333">{{ offlinePlaceTypeName }}</div>
              <van-icon name="arrow" color="#ccc" />
            </div>
          </div>
          <!-- 选择平台推荐的场所  1使用平台推荐的场所 2使用自己的场所-->
          <div v-if="offlinePlaceType == 1" class="offline-place second">
            <div class="offline-title fs24 fc666 ">
              选择平台推荐的场所
            </div>
            <div
              @click="goPlacePage"
              class="mt20 fs28 fc333 dfr cross-center main-between"
            >
              <div v-if="!placeId" class="placeholder  fcccc  ">
                请选择平台推荐的场所
              </div>
              <div v-else class="box fc333">{{ placeName }}</div>
              <van-icon name="arrow" color="#ccc" />
            </div>
          </div>
          <div v-if="offlinePlaceType == 2" class="offline-place second">
            <div class="offline-title fs24 fc666 ">
              自己的场所地址
            </div>
            <div class="inner mt20 fs28 fc333 dfr cross-center main-between">
              <van-field
                clearable
                v-model="offlineOwnAddress"
                @blur="offlineOwnAddressBlur"
                placeholder="请填写自己的完整的场地地址"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 选择会议场所 -->
    <van-action-sheet
      v-model="offlineTypePopupShow"
      :actions="offlinePlaceSelectList"
      cancel-text="取消"
      close-on-click-action
      @cancel="offlinePlaceSelectCancel"
      @select="offlinePlaceSelect"
    />

    <app-title class="add-m-t" title="会议的讨论议题"></app-title>
    <!-- 我的困惑是，我如何 -->
    <div class="cell-box ">
      <div class="cell-title">我的困惑是，我如何</div>
      <div class="inner fs28 dfr cross-center main-between">
        <van-field
          clearable
          v-model.trim="q1"
          @blur="aBlur"
          placeholder="如“做好新项目的定位和运营”"
        />
      </div>
    </div>
    <!-- 这个问题是重要的，因为 -->
    <div class="cell-box ">
      <div class="cell-title">这个问题是重要的，因为</div>
      <div class="inner fs28 dfr cross-center main-between">
        <van-field
          clearable
          v-model.trim="q2"
          @blur="aBlur"
          placeholder="如“关系到新项目是否能够成功”"
        />
      </div>
    </div>
    <!-- 到目前为止，为了解决这个问题我已经做了 -->
    <div class="cell-box ">
      <div class="cell-title">到目前为止，为了解决这个问题我已经做了</div>
      <div class="inner fs28 dfr cross-center main-between">
        <van-field
          clearable
          v-model.trim="q3"
          @blur="aBlur"
          placeholder="如“市场调研，招募了运营人员等”"
        />
      </div>
    </div>
    <!-- 我希望小组帮助我的是 -->
    <div class="cell-box ">
      <div class="cell-title">我希望小组帮助我的是</div>
      <div class="inner fs28 dfr cross-center main-between">
        <van-field
          clearable
          v-model.trim="q4"
          @blur="aBlur"
          placeholder="如“提供更好的运营方法等”"
        />
      </div>
    </div>

    <app-title class="add-m-t" title="图片附件"></app-title>
    <!-- 图片附件 -->
    <div class="uploader-box dfr cross-center flex-wrap">
      <div v-for="(item, index) in uploadImgs" :key="index" class="uploadImgs">
        <img class="upload-img w100 h100 object-cover" :src="item" alt="" />
        <div @click="deleteImgs(index)" class="delete box">
          <img class="w100 h100 " src="../assets/images/delete.png" alt="" />
        </div>
      </div>
      <div class="uploadImgs ">
        <van-uploader
          class="uploader-vant"
          :max-count="10"
          preview-size="2.12rem"
          multiple
          :after-read="afterRead"
          :upload-icon="uploadIcon"
        />
      </div>
    </div>

    <div class="b-button-box bgfff">
      <div class="recruiting-button dfr flex-center">
        <icy-button @click.native="next">下一步</icy-button>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import OSS from "ali-oss";
export default {
  data() {
    return {
      isReplaceCreate: false,
      fieldId: "",
      fieldIds: [],
      fieldDefaultIndex: "",
      fieldList: [],
      fieldValue: "",
      fieldPopupShow: false,
      dateValue: "",
      minDate: "",
      maxDate: "",
      beDate: "", // 传入后台的date
      datePopupShow: false,
      currentTimeIndex: "",
      timeList: ["09:00-12:00", "14:00-17:00", "19:00-21:00"],
      timeValue: "",
      currentTime: "", // 用于传后台数据，不显示
      timePopupShow: false,
      joinNum: 10,
      q1: "",
      q2: "",
      q3: "",
      q4: "",
      fileList: [],
      uploadImgs: [],
      uploadIcon: require("../assets/images/meeting.png"),
      moderator: "",
      moderatorId: "",
      moderatorInfo: {},
      sidong: "",
      sidongId: "",
      sidongInfo: {},
      // TODO 1线下 2线上
      meetingMethodsList: [
        { name: "线上会议", type: 2 },
        { name: "线下会议", type: 1 }
      ],
      meetingMethodsType: 1,
      meetingMethods: "线下会议",
      meetingMethodsPopupShow: false,
      onlineName: "腾讯会议房间号",
      onlineRoom: "",
      // TODO 1使用平台推荐的场所 2使用自己的场所
      offlinePlaceType: 1,
      offlinePlaceTypeName: "使用平台推荐的场所",
      offlineTypePopupShow: false,
      offlineOwnAddress: "",
      offlinePlaceSelectList: [
        { name: "使用平台推荐的场所", type: 1 },
        { name: "使用自己的场所", type: 2 }
      ],
      placeId: "",
      placeName: "",
      placeAddress: "",
      isReplace: 0,
      // 代案主发起会议 1:当前日期时间段不能发起会议 0:能
      isHostMeeting: 1,
      // 0未开启会议时间  1开启了会议时间
      isHaveHostTime: 0
    };
  },
  created() {
    this.isReplace = parseInt(this.$route.query.isReplace) || 0;
    this.$title("发起会议");
    const meetingStoreData = this.$localStore.get("meetingData");
    if (meetingStoreData) {
      this.setDefaultData(meetingStoreData);
    } else {
      this.saveStoreData();
    }
    this.minDate = new Date(
      // dayjs()
      //   .add(1, "day")
      //   .format("YYYY/MM/DD")
      dayjs().format("YYYY/MM/DD")
    );
    this.maxDate = new Date("2100/01/01");
    this.initPage();
  },
  mounted() {
    let scrollTop = this.$localStore.get("meetingScrollTop");
    window.scrollTo(0, scrollTop);
  },
  beforeRouteLeave(to, from, next) {
    const getScrollTop = () => {
      var scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      return scrollTop;
    };
    let scrollTop = getScrollTop();
    this.$localStore.set("meetingScrollTop", scrollTop);
    next();
  },
  methods: {
    deleteImgs(index) {
      this.uploadImgs.splice(index, 1);
      this.saveStoreData();
    },
    setDefaultData(meetingStoreData) {
      this.fieldId = meetingStoreData.fieldId;
      this.fieldDefaultIndex = meetingStoreData.fieldDefaultIndex;
      this.fieldValue = meetingStoreData.fieldValue;
      this.dateValue = meetingStoreData.dateValue;

      this.beDate = new Date(
        dayjs(meetingStoreData.beDate).format("YYYY/MM/DD")
      );
      this.currentTime = meetingStoreData.currentTime;
      this.timeValue = meetingStoreData.timeValue;
      this.currentTimeIndex = meetingStoreData.currentTimeIndex;
      this.moderator = meetingStoreData.moderator;
      // TODO 判断是否是代案主发起
      if (this.isReplace == 0) {
        this.moderatorInfo = meetingStoreData.moderatorInfo;
        this.moderatorId = meetingStoreData.moderatorId;
      }

      this.sidong = meetingStoreData.sidong;
      this.sidongId = meetingStoreData.sidongId;
      this.sidongInfo = meetingStoreData.sidongInfo;
      this.joinNum = meetingStoreData.joinNum;
      this.meetingMethodsType = meetingStoreData.meetingMethodsType;
      this.meetingMethods = meetingStoreData.meetingMethods;
      this.onlineName = meetingStoreData.onlineName;
      this.onlineRoom = meetingStoreData.onlineRoom;
      this.offlinePlaceType = meetingStoreData.offlinePlaceType;
      this.offlinePlaceTypeName = meetingStoreData.offlinePlaceTypeName;
      this.offlineOwnAddress = meetingStoreData.offlineOwnAddress;
      this.placeId = meetingStoreData.placeId;
      this.placeName = meetingStoreData.placeName;
      this.placeAddress = meetingStoreData.placeAddress;
      //
      this.q1 = meetingStoreData.q1;
      this.q2 = meetingStoreData.q2;
      this.q3 = meetingStoreData.q3;
      this.q4 = meetingStoreData.q4;
      this.fileList = meetingStoreData.fileList;
      this.uploadImgs = meetingStoreData.uploadImgs;
    },
    saveStoreData() {
      const data = {
        fieldDefaultIndex: this.fieldDefaultIndex,
        fieldId: this.fieldId,
        fieldValue: this.fieldValue,
        beDate: this.beDate,
        dateValue: this.dateValue,
        joinNum: this.joinNum,
        currentTime: this.currentTime,
        timeValue: this.timeValue,
        currentTimeIndex: this.currentTimeIndex,
        moderatorId: this.moderatorId,
        moderator: this.moderator,
        moderatorInfo: this.moderatorInfo,
        sidong: this.sidong,
        sidongId: this.sidongId,
        sidongInfo: this.sidongInfo,
        meetingMethods: this.meetingMethods, // 会议形式名称
        meetingMethodsType: this.meetingMethodsType, // 会议形式 1:线下 2:线上
        onlineName: this.onlineName, // 腾讯会议房间号
        onlineRoom: this.onlineRoom, // 房间号
        offlinePlaceTypeName: this.offlinePlaceTypeName, // 会议场所形式名称
        offlinePlaceType: this.offlinePlaceType, // 1:使用平台推荐的场所 2:使用自己的场所
        placeId: this.placeId, // 平台推荐的场所 id
        placeName: this.placeName, // 平台推荐的场所名称
        placeAddress: this.placeAddress, // 平台推荐的场所地址
        offlineOwnAddress: this.offlineOwnAddress, // 自己的场所
        q1: this.q1,
        q2: this.q2,
        q3: this.q3,
        q4: this.q4,
        fileList: this.fileList,
        uploadImgs: this.uploadImgs
      };
      this.$localStore.set("meetingData", data);
    },
    clearStoreData() {
      const meetingStoreData = this.$localStore.get("meetingData");
      meetingStoreData.moderator = "";
      meetingStoreData.moderatorId = "";
      meetingStoreData.sidong = "";
      meetingStoreData.sidongId = "";
      meetingStoreData.currentTime = "";
      meetingStoreData.beDate = "";
      this.$localStore.set("meetingData", meetingStoreData);
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      } else if (type === "day") {
        return `${val}日`;
      }
      return val;
    },
    initPage() {
      this.initField();
      if (this.isReplace) {
        this.getDefault();
      }
    },

    goModerator() {
      const timeTypes = {
        "09:00-12:00": 0,
        "14:00-17:00": 1,
        "19:00-21:00": 2
      };
      this.$go("moderator", {
        date: this.dateValue,
        timeType: timeTypes[this.timeValue],
        isReplace: this.isReplace
      });
    },
    goSidong() {
      this.$go("directors", {
        select: 1,
        isReplace: this.isReplace
      });
    },
    async initField() {
      this.fieldList = [];
      this.fieldIds = [];
      const resp = await this.$API
        .get("fieldList", "", false)
        .then(resp => resp);
      if (resp.code == 1000) {
        resp.data.forEach(item => {
          this.fieldList.push(item.name);
          this.fieldIds.push(item.id);
        });
      }
    },
    // 获取默认主持人
    async getDefault() {
      const timeTypes = {
        "09:00-12:00": 0,
        "14:00-17:00": 1,
        "19:00-21:00": 2
      };
      let hold_type = "";
      if (this.timeValue != "") {
        hold_type = timeTypes[this.timeValue];
      }

      let hold_date = dayjs(this.beDate).format("YYYY-MM-DD");
      if (hold_date == "Invalid Date") {
        hold_date = "";
      }
      const data = {
        hold_type,
        hold_date
      };

      const resp = await this.$API.get("default", data).then(resp => resp);
      if (resp.code == 1000) {
        this.moderatorInfo = resp.data;
        this.moderatorId = resp.data.id;
        this.isHostMeeting = resp.data.is_host_meeting;
        this.isHaveHostTime = resp.data.is_have_host_time;
        this.saveStoreData();
      }
    },
    showFieldHandle() {
      this.fieldPopupShow = true;
    },
    async fieldConfirm(value, index) {
      this.fieldValue = value;
      this.fieldId = this.fieldIds[index];
      this.fieldDefaultIndex = index;
      this.fieldPopupShow = false;
      this.saveStoreData();
    },
    showDateHandle() {
      this.datePopupShow = true;
    },
    showTimeHandle() {
      this.timePopupShow = true;
    },
    dateConfirm(data) {
      let YYYY = dayjs(data).format("YYYY");
      let MM = dayjs(data).format("MM");
      let DD = dayjs(data).format("DD");
      this.dateValue = `${YYYY}年${MM}月${DD}日`;
      this.beDate = `${YYYY}-${MM}-${DD}`;
      this.datePopupShow = false;
      if (!this.isReplace) {
        this.moderatorInfo = "";
        this.moderator = "";
        this.moderatorId = "";
      }
      this.saveStoreData();
      if (this.isReplace) {
        this.getDefault();
      }
    },
    dateChange(data) {
      this.dateValue = data.getValues().join("");
      this.saveStoreData();
      if (this.isReplace) {
        this.getDefault();
      }
    },
    timeConfirm(data, index) {
      //  timeList: ["09:00-12:00", "14:00-17:00", "19:00-21:00"],
      this.timeValue = data;
      this.currentTimeIndex = index;
      this.currentTime = data;
      this.timePopupShow = false;
      if (!this.isReplace) {
        this.moderatorInfo = "";
        this.moderator = "";
        this.moderatorId = "";
      }
      this.saveStoreData();
      if (this.isReplace) {
        this.getDefault();
      }
    },
    timeChange(data) {
      this.timeValue = data.getValues().join("");
      this.saveStoreData();
      if (this.isReplace) {
        this.getDefault();
      }
    },
    joinBlur() {
      if (this.joinNum >= 15) {
        this.joinNum = 15;
      }
      if (this.joinNum <= 7) {
        this.joinNum = 7;
      }
      this.saveStoreData();
    },
    aBlur() {
      this.saveStoreData();
    },
    offlineOwnAddressBlur() {
      this.saveStoreData();
    },
    /**
     * 轻提示
     */
    toastFun(msg) {
      this.toast = this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 禁用背景点击
        loadingType: "spinner",
        message: msg
      });
    },
    /**
     * 上传到ali oss
     */
    async uploadAliOss(file) {
      const _this = this;
      const fileSize = file.size; // (单位字节)
      const parallelSize = 1024 * 1024 * 30; // 20M以下不需要用切片上传 (单位字节)
      const uuid = this.$uuidv4();
      const endpoint = this.$config.endpoint;
      const bucket = this.$config.bucket;
      const aliOssUrlPrefix = this.$config.aliOssUrlPrefix;

      const region = this.$config.region;
      const resp = await this.$API
        .post("ossToken")
        .then(resp => resp)
        .catch(err => {
          this.$toast(err.msg);
        });
      const ossConfig = {
        accessKeyId: resp.data.AccessKeyId,
        accessKeySecret: resp.data.AccessKeySecret,
        stsToken: resp.data.SecurityToken,
        region,
        endpoint,
        bucket
      };
      const client = new OSS(ossConfig);

      // 定义切片上传方法
      async function multipartUpload() {
        await client.multipartUpload(uuid, file, {
          parallel: 4,
          partSize: 1024 * 1024,
          progress: function() {
            // _this.$toast.message = `上传进度${(p * 100).toFixed(0)}%`;
          }
        });

        const url = `${aliOssUrlPrefix}${uuid}${file.name}`;

        _this.getUploadContent(file, url);
        _this.$toast("上传成功");
      }

      // 定义小文件上传方法
      async function minFileUpload() {
        await client.put(`${uuid}${file.name}`, file);
        const url = `${aliOssUrlPrefix}${uuid}${file.name}`;
        _this.getUploadContent(file, url);
        _this.$toast("上传成功");
      }

      // 开始上传
      if (fileSize < parallelSize) {
        minFileUpload();
      } else {
        multipartUpload();
      }
    },
    // TODO 文件过大
    getUploadContent(file, url) {
      if (!this.uploadImgs) {
        this.uploadImgs = [];
      }
      this.uploadImgs.push(url);

      this.saveStoreData();
    },
    afterRead(file) {
      this.toastFun("正在读取...");
      const pressImg = file => {
        this.$lrz(file)
          .then(res => {
            this.uploadAliOss(res.file);
          })
          .catch(err => {
            this.$toast(err);
          });
      };
      // 判断上传文件是单个还是多个
      if (file.length) {
        const fileList = file;

        for (let item of fileList) {
          pressImg(item.file);
        }
      } else {
        pressImg(file.file);
      }
    },
    deleteImg(file, index) {
      this.uploadImgs.splice(index, 1);
      this.saveStoreData();
    },
    next() {
      if (!this.fieldValue) {
        this.$toast("请选择所属领域");
        return;
      }
      if (!this.beDate) {
        this.$toast("请选择会议日期");
        return;
      }
      if (this.currentTime === "") {
        this.$toast("请选择会议时间");
        return;
      }
      if (this.isReplace == 1) {
        if (!this.sidongInfo || !this.sidongInfo.id) {
          this.$toast("请选择会议的案主");
          return;
        }
      }
      if (!this.moderatorId) {
        this.$toast("请选择主持人");
        return;
      }
      if (!this.joinNum) {
        this.$toast("请输入参会私董人数");
        return;
      }

      // 1线下 2线上
      if (this.meetingMethodsType == 1) {
        //  1使用平台推荐的场所 2使用自己的场所
        if (this.offlinePlaceType == 1) {
          if (!this.placeId) {
            this.$toast("请选择平台推荐的场所");
            return;
          }
        } else if (this.offlinePlaceType == 2) {
          if (!this.offlineOwnAddress) {
            this.$toast("请填写自己的完整的场地地址");
            return;
          }
        }
      } else if (this.meetingMethodsType == 2) {
        if (!this.onlineRoom) {
          this.$toast("请填写腾讯会议房间号");
          return;
        }
      }

      if (!this.q1) {
        this.$toast("请输入会议议题");
        return;
      }
      if (!this.q2) {
        this.$toast("请输入会议议题");
        return;
      }
      if (!this.q3) {
        this.$toast("请输入会议议题");
        return;
      }
      if (!this.q4) {
        this.$toast("请输入会议议题");
        return;
      }
      if (this.isReplace == 1) {
        if (this.isHaveHostTime == 0) {
          this.$toast("您没有在此日期时间段设置会议日程，不能发起会议！");
          return;
        }
        if (this.isHostMeeting == 1) {
          this.$toast("您的此日期时间段已有会议，不能发起会议！");
          return;
        }
      }
      this.$go("order", { isReplace: this.isReplace });
    },
    // 会议召开形式
    showMeetingMethodsHandle() {
      this.meetingMethodsPopupShow = true;
    },
    meetingMethodsCancel() {
      this.meetingMethodsPopupShow = false;
    },
    meetingMethodsSelect(e) {
      this.meetingMethods = e.name;
      this.meetingMethodsType = e.type;
      this.saveStoreData();
    },
    onlineRoomBlur() {
      this.saveStoreData();
    },
    showOfflineTypeHandle() {
      this.offlineTypePopupShow = true;
    },
    offlinePlaceSelectCancel() {
      this.offlineTypePopupShow = false;
    },
    offlinePlaceSelect(e) {
      this.offlinePlaceTypeName = e.name;
      this.offlinePlaceType = e.type;
      this.saveStoreData();
    },
    goPlacePage() {
      this.$go("siteList", { select: 1, isReplace: this.isReplace });
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.uploadImgs {
  width: 2.12rem;
  height: 2.12rem;
  position: relative;
  margin-right: 0.21rem;
  margin-bottom: 0.21rem;
  box-sizing: border-box;
  overflow: hidden;
  &:nth-child(3n) {
    margin-right: 0;
  }
  .delete {
    position: absolute;
    top: 0;
    right: 0;
    width: 0.26rem;
    height: 0.26rem;
  }
  .uploader-vant {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.transform-y {
  transform: translateY(-0.04rem);
}
.transform-time {
  transform: translateY(-0.02rem);
}

.moderator-avatar-box {
  .avatar {
    width: 0.48rem;
    height: 0.48rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 0.12rem;
  }
}
.moderator-box {
  transform: scale(1.2);
  transform-origin: right center;
}
.create-meeting {
  padding: 0.36rem;
  .meeting-box {
    margin-top: 0.36rem;
  }

  .cell-box {
    padding: 0 0 0.16rem;
    border-bottom: 0.01rem solid #eee;
    margin-top: 0.36rem;
    .inner {
      min-height: 0.4rem;
      margin-top: 0.2rem;
      .placeholder {
        color: #cccccc;
      }
    }
  }
  .add-m-t {
    margin-top: 0.8rem;
  }
  .bottom-btn {
    height: 1.2rem;
  }
  .uploader-box {
    margin-top: 0.36rem;
  }
}
.b-button-box {
  height: calc(1.2rem + env(safe-area-inset-bottom));
  height: calc(1.2rem + constant(safe-area-inset-bottom));
  .recruiting-button {
    padding: 0.2rem 0.36rem;
    padding-bottom: calc(0.2rem + env(safe-area-inset-bottom));
    padding-bottom: calc(0.2rem + constant(safe-area-inset-bottom));
    width: 7.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
  }
}

.meeting-methods-online {
  padding: 0.36rem 0 0;
  background: #fcfcfc;
  .online-left {
    width: 0.18rem;
    height: 0.18rem;
    background: #ffffff;
    border: 0.04rem solid #dfdfdf;
    box-sizing: border-box;
    border-radius: 50%;
    margin-right: 0.2rem;
    transform: translateY(0.07rem);
  }
  .online-right {
    padding: 0 0 0.18rem;
    border-bottom: 0.01rem solid #eee;
  }
}
.meeting-methods-offline {
  padding: 0.36rem 0 0;
  background: #fcfcfc;
  .offline-left {
    .line {
      height: 1.18rem;
      width: 0.02rem;
      background: #dfdfdf;
      transform: translate(0.08rem, 0.06rem);
    }
    .dot {
      width: 0.18rem;
      height: 0.18rem;
      background: #ffffff;
      border: 0.04rem solid #dfdfdf;
      box-sizing: border-box;
      border-radius: 50%;
      margin-right: 0.2rem;
      transform: translateY(0.07rem);
    }
  }
  .offline-right {
    .offline-place {
      border-bottom: 0.01rem solid #eee;
      padding: 0 0 0.18rem;
      height: 1.1rem;
      &.second {
        padding-top: 0.28rem;
        height: 1.42rem;
      }
    }
  }
}
</style>
